import '@babel/polyfill';
import '../css/style.css';
import '../scss/forms.scss';

require('popper.js');
const $ = require('jquery');
require('bootstrap');
require('leaflet/dist/leaflet.css');
require('../css/style.css');
require('bootstrap/dist/css/bootstrap.css');
const L = require('leaflet');

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  imagePath: 'static/dist/',
});


const instantiateMap = (coordinates, zoomLevel) => {
  const map = L.map('map').setView([coordinates[1], coordinates[0]], zoomLevel);

  L.tileLayer('//{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  }).addTo(map);

  return map;
};

const geocoding = async (address, city) => {
  try {
    const response = await fetch(`//nominatim.openstreetmap.org/?format=json&q=${address} ${city}`);
    const data = await response.json();
    return [data[0].lat, data[0].lon];
  } catch (error) {
    $.nofity(
      { message: gettext("Something wen't wrong with the geocoding services") },
      { type: 'danger', placement: { from: 'top', align: 'center' } },
    );
    return error;
  }
};

const saveToLocalStorage = valueName => event => {
  const selectedOption = event.target.options[event.target.selectedIndex];
  window.localStorage.setItem(valueName, JSON.stringify({ [selectedOption.value]: selectedOption.innerText }));
};

const setSelectedOption = (select, value, text) => {
  const selectedOption = document.createElement('option');
  selectedOption.selected = true;
  selectedOption.value = value;
  selectedOption.innerText = text;
  $(select).append(selectedOption);  // use jquery to be sure of IE11 compat
};

document.addEventListener('DOMContentLoaded', () => {
  const companyJson = document.getElementById('company-json');
  if (companyJson) {
    const company = JSON.parse(companyJson.innerText);
    const { coordinates } = company.geometry;
    const map = instantiateMap(coordinates, 15);
    L.marker([coordinates[1], coordinates[0]]).addTo(map);
  }

  // for signup page
  const signUpForm = document.querySelector('#signup');
  if (signUpForm) {
    // wizardForm autocomplete management
    const currentStep = signUpForm.querySelector('#id_sign_up_wizard-current_step').value;
    const activityCodeItem = JSON.parse(window.localStorage.getItem('activityCode'));
    const industryItem = JSON.parse(window.localStorage.getItem('industry'));
    const cityItem = JSON.parse(window.localStorage.getItem('city'));

    if (currentStep === 'step3') {
      // https://github.com/yourlabs/django-autocomplete-light/issues/1053
      const activityCodeField = signUpForm.querySelector('#id_step3-activity_code');
      activityCodeField.onchange = saveToLocalStorage('activityCode');
      const industryField = signUpForm.querySelector('#id_step3-industry');
      industryField.onchange = saveToLocalStorage('industry');
      const cityField = signUpForm.querySelector('#id_step3-city');
      cityField.onchange = saveToLocalStorage('city');

      if (cityItem) {
        const [value, text] = Object.entries(cityItem)[0];
        setSelectedOption(cityField, value, text);
      }
      if (industryItem) {
        const [value, text] = Object.entries(industryItem)[0];
        setSelectedOption(industryField, value, text);
      }
      if (activityCodeItem) {
        const [value, text] = Object.entries(activityCodeItem)[0];
        setSelectedOption(activityCodeField, value, text);
      }
    } else if (currentStep === 'step4') {
      const activityName = document.getElementById('summary_activity');
      activityName.innerText = activityCodeItem[activityName.innerText];

      const industryName = document.getElementById('summary_industry');
      industryName.innerText = industryItem[industryName.innerText];

      const cityName = document.getElementById('summary_city');
      cityName.innerText = cityItem[cityName.innerText];
    }

    // map settings
    const defaultCoords = [4.079306, 48.293024];
    const map = instantiateMap(defaultCoords, 6);
    const marker = L.marker(defaultCoords).addTo(map);

    map.on('click', event => {
      const { lat, lng } = event.latlng;
      signUpForm.querySelector('#id_step3-lat').value = lat;
      signUpForm.querySelector('#id_step3-lng').value = lng;
      marker.setLatLng(event.latlng);
    });

    document.getElementById('localize').addEventListener('click', async event => {
      event.preventDefault();
      const address = document.getElementById('id_step3-address').value;
      const citySelect = document.getElementById('id_step3-city');
      const city = citySelect.options[citySelect.selectedIndex].innerText; // value is db id, we want text for NORMATIM
      const [lat, lng] = await geocoding(address, city);
      signUpForm.querySelector('#id_step3-lat').value = lat;
      signUpForm.querySelector('#id_step3-lng').value = lng;
      marker.setLatLng([lat, lng]);
    });
  }

  if (document.getElementById('signup-done')) {
    window.localStorage.clear();
  }

  $('#btn-add-flux').on('click', event => {
    event.preventDefault();

    $.ajax({
      url: '/flux/add/',
      processData: false,
      contentType: false,
      type: 'GET',
      success (data) {
        $('.modal-title').html('Ajouter un flux');
        $('.modal-body').html(data);
      },
    });
  });
});
